<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-title"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-title"
        name="input-title"
        v-model="$v.rubric_preset_unit.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 2 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="rubric_preset_unit-form-description rich-text-content mb-2"
        v-html="$v.rubric_preset_unit.description.$model"
        @click="
          $bvModal.show(
            `edit-rubric_preset_unit-description-modal-${rubric_preset_unit.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-rubric_preset_unit-description-modal-${rubric_preset_unit.id}`"
        title="Editar Descripción de la Evaluación"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="rubric_preset_unit"
          :Text="rubric_preset_unit.description"
          @save="patchRubricUnitDescription"
          @close="
            $bvModal.hide(
              `edit-rubric_preset_unit-description-modal-${rubric_preset_unit.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${
        RubricPreset && RubricPreset.show_percentage_sign
          ? 'Porcentaje'
          : 'Puntaje'
      }:`"
      label-for="input-score"
      label-cols="4"
      label-cols-sm="3"
    >
      <b-form-input
        id="input-score"
        v-model="$v.rubric_preset_unit.score.$model"
        :state="validateState('score')"
        aria-describedby="input-score-feedback"
        size="sm"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback">
        <span>Este campo debe ser mayor o igual a 0</span>
        <span v-if="RubricPreset && RubricPreset.show_percentage_sign">
          y menor a o igual a 100.
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.rubric_preset_unit.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteRubricPreset"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "RubricPresetForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    RubricPreset: {
      type: Object,
      required: true,
    },
    RubricPresetUnit: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          order: 0,
          score: 0,
          rubric_preset: this.RubricPreset.id,
        };
      },
    },
    // Variable para que al guardar se cree el objeto en bd o enviarla por emit al componente padre al crear o editar.
    permit_local_rubric_preset_unit: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric_preset_unit: { ...this.RubricPresetUnit },
    };
  },
  validations: {
    rubric_preset_unit: {
      title: {
        required,
        minLength: minLength(2),
      },
      description: {},
      score: {
        required,
        minValue: minValue(0),
        isValid() {
          if (this.RubricPreset && this.RubricPreset.show_percentage_sign) {
            if (this.rubric_preset_unit.score > 100) return false;
            else return true;
          } else return true;
        },
      },
    },
  },
  computed: {
    ...mapGetters({ rubric_preset_units: names.RUBRIC_PRESET_UNITS }),
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.rubric_preset_unit[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.rubric_preset_unit.$touch();
      if (this.$v.rubric_preset_unit.$anyError) {
        return;
      }
      if (this.permit_local_rubric_preset_unit) {
        this.$emit("local_save", this.rubric_preset_unit);
      } else {
        if (isNaN(this.rubric_preset_unit.id)) {
          this.createRubricPreset();
        } else this.updateRubricPreset();
      }
    },
    patchRubricUnitDescription(object, text) {
      this.rubric_preset_unit.description = text;
    },
    createRubricPreset() {
      this.rubric_preset_unit.order = this.rubric_preset_units.length + 1;
      this.$store
        .dispatch(names.POST_RUBRIC_PRESET_UNIT, this.rubric_preset_unit)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "evaluations.observationachievement",
                false,
                "Niveles de Logro"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateRubricPreset() {
      this.$store
        .dispatch(names.UPDATE_RUBRIC_PRESET_UNIT, this.rubric_preset_unit)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "evaluations.observationachievement",
                false,
                "Niveles de Logro"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteRubricPreset() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Niveles de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_RUBRIC_PRESET_UNIT,
            this.rubric_preset_unit.id
          );
          this.$emit("deleted", this.rubric_preset_unit);
        }
      });
    },
  },
};
</script>

<style scoped>
.rubric_preset_unit-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.rubric_preset_unit-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre","label-for":"input-title","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-title","name":"input-title","state":_vm.validateState('title'),"aria-describedby":"input-title-live-feedback","size":"sm"},model:{value:(_vm.$v.rubric_preset_unit.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_preset_unit.title, "$model", $$v)},expression:"$v.rubric_preset_unit.title.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 2 caracteres.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":"Descripción","label-for":"input-description","label-cols":"4","label-cols-sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:('Editar Descripción'),expression:"'Editar Descripción'",modifiers:{"v-secondary":true}}],staticClass:"rubric_preset_unit-form-description rich-text-content mb-2",domProps:{"innerHTML":_vm._s(_vm.$v.rubric_preset_unit.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-rubric_preset_unit-description-modal-${_vm.rubric_preset_unit.id}`
        )}}}),_c('b-modal',{attrs:{"id":`edit-rubric_preset_unit-description-modal-${_vm.rubric_preset_unit.id}`,"title":"Editar Descripción de la Evaluación","size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.rubric_preset_unit,"Text":_vm.rubric_preset_unit.description},on:{"save":_vm.patchRubricUnitDescription,"close":function($event){return _vm.$bvModal.hide(
            `edit-rubric_preset_unit-description-modal-${_vm.rubric_preset_unit.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-feedback"}},[_vm._v("Este campo es opcional.")])],1),_c('b-form-group',{attrs:{"label":`${
      _vm.RubricPreset && _vm.RubricPreset.show_percentage_sign
        ? 'Porcentaje'
        : 'Puntaje'
    }:`,"label-for":"input-score","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-score","state":_vm.validateState('score'),"aria-describedby":"input-score-feedback","size":"sm","type":"number"},model:{value:(_vm.$v.rubric_preset_unit.score.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_preset_unit.score, "$model", $$v)},expression:"$v.rubric_preset_unit.score.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-score-feedback"}},[_c('span',[_vm._v("Este campo debe ser mayor o igual a 0")]),(_vm.RubricPreset && _vm.RubricPreset.show_percentage_sign)?_c('span',[_vm._v(" y menor a o igual a 100. ")]):_vm._e()])],1),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(this.rubric_preset_unit.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteRubricPreset}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"m-0",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }